// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: true,
    apiUrl: 'https://api.membire.com/v1/api',
    centralApi: 'https://api.membire.com/v1/api',
    tenantApi: getTenantApi(),
    fullTenantApiUrl: getFullTenantApiUrl(),
    authTenantApiUrl: getAuthTenantApiUrl(),
};

function getTenantApi(): string {
    return typeof window !== 'undefined' ? `https://${window.location.host.split('.')[0]}.api.membire.com/v1/api` : '';
}

function getFullTenantApiUrl(): string {
    return typeof window !== 'undefined' ? `https://${window.location.host.split('.')[0]}.api.membire.com/v1/api/lms` : '';
}

function getAuthTenantApiUrl(): string {
    return typeof window !== 'undefined' ? `https://${window.location.host.split('.')[0]}.api.membire.com/v1/api` : '';
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
